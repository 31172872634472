import {
  EBannerCardWithTitleCardsTheme,
  BannerCardWithTitleCards,
  ETitleCardTheme,
  toImageProps,
} from '@front/shared/ds';
import { Section } from '@shared/master-types';
import React from 'react';

export type TBannerCardWithTitleCardsConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'banner-card-w-title-cards' }
>;

const BannerCardWithTitleCardsConnected = (
  props: TBannerCardWithTitleCardsConnectedProps,
): JSX.Element => {
  const { cards, theme, ...rest } = props;

  return (
    <BannerCardWithTitleCards
      cards={
        cards?.map(item => ({
          icon: toImageProps(item.icon),
          title: item.title,
          cardTheme: item.cardTheme as ETitleCardTheme,
        })) || []
      }
      theme={theme as EBannerCardWithTitleCardsTheme}
      {...rest}
    />
  );
};

export default BannerCardWithTitleCardsConnected;
