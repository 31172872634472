import { TreeDiagram } from '@front/shared/ds';
import { Section } from '@shared/master-types';
import React from 'react';

export type TTreeDiagramConnectedConnectedProps = Extract<
  NonNullable<Section['rows']>[number],
  { blockType: 'tree-diagram' }
>;

const TreeDiagramConnected = (
  props: TTreeDiagramConnectedConnectedProps,
): JSX.Element => {
  const { row1, row2, row3, row4 } = props;
  return <TreeDiagram row1={row1} row2={row2} row3={row3} row4={row4} />;
};

export default TreeDiagramConnected;
