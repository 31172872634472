import { DefaultCard, PlatesDefaultCard } from '@front/shared/ds';
import { Section } from '@shared/master-types';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

import { useAppDispatch } from '../../../store/store.hooks';
import { prepareDefaultCardFromRawData } from '../DefaultCardsConnected';

export type TPlatesDefaultCardConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'plates-default-card' }
>;

export const PlatesDefaultCardConnected: React.FC<
  TPlatesDefaultCardConnectedProps
> = props => {
  const { cards = [], ...rest } = props;
  const appDispatch = useAppDispatch();
  const router = useRouter();
  const currentLocale = String(router.query.locale || '');

  const banners: React.ComponentProps<typeof DefaultCard>[] = useMemo(() => {
    return cards.map(card =>
      prepareDefaultCardFromRawData(card, currentLocale, appDispatch),
    );
  }, [cards, currentLocale, appDispatch]);

  return <PlatesDefaultCard cards={banners} {...rest} />;
};

export default PlatesDefaultCardConnected;
